import React from "react";

const Regulamin = () => {
	return (
		<div className="legal-page">
			<h1>Regulamin</h1>
			<p>Regulamin sprzedaży koszulek - Drop Walentynkowy
                <br></br>

1. Postanowienia ogólne
1.1. Regulamin określa zasady sprzedaży koszulek w ramach akcji "Drop Walentynkowy" prowadzonej na stronie internetowej https://bcrstudio.pl/shop. 1.2. Organizatorem sprzedaży jest Bolesławieckie Centrum Ruchu oraz Trener Amadeusz Dziemianko z siedzibą pod adresem: Staszica 9, 59-700 Bolesławiec, NIP: 6121879187. 1.3. Zakup koszulek możliwy jest online oraz na miejscu, z odbiorem osobistym pod adresem Staszica 9, 59-700 Bolesławiec.
<br></br>
2. Warunki zakupu
2.1. Zamówienia można składać za pośrednictwem strony internetowej od 14.02.2025 do wyczerpania zapasów. 2.2. Liczba koszulek jest ograniczona – decyduje kolejność zgłoszeń. 2.3. Każde zamówienie musi zostać opłacone z góry za pomocą dostępnych metod płatności na stronie. 2.4. Po złożeniu i opłaceniu zamówienia, klient otrzyma potwierdzenie na podany adres e-mail.
<br></br>
3. Odbiór osobisty
3.1. Odbiór zamówionych koszulek możliwy jest jedynie osobiście pod adresem: Staszica 9, 59-700 Bolesławiec. 3.2. Odbiór będzie możliwy w dni robocze w godzinach 15:00-20:00. 3.3. W celu odbioru należy okazać dowód zakupu (np. e-mail z potwierdzeniem zamówienia). 3.4. Nieodebranie zamówienia w wyznaczonym terminie nie uprawnia do zwrotu płatności.
<br></br>
4. Zwroty i reklamacje
4.1. Zwroty nie są możliwe, ponieważ każde zamówienie jest realizowane na indywidualne zamówienie klienta. 4.2. Reklamacje dotyczące wadliwego towaru można zgłaszać na adres e-mail [adres e-mail] w ciągu 14 dni od daty odbioru. 4.3. Reklamacja zostanie rozpatrzona w ciągu 14 dni roboczych od daty zgłoszenia.
<br></br>
5. Postanowienia końcowe
5.1. Organizator zastrzega sobie prawo do zmian w regulaminie oraz zakończenia sprzedaży w dowolnym momencie. 5.2. Wszelkie spory wynikające z realizacji sprzedaży będą rozstrzygane zgodnie z prawem polskim. 5.3. Kontakt w sprawie sprzedaży: studiotreningowebcr@gmail.com

</p>
		</div>
	);
};

export default Regulamin;
