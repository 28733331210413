import React from "react";

const PolitykaPrywatnosci = () => {
	return (
		<div className="legal-page">
			<h1>Polityka Prywatności</h1>
			<p>Polityka Prywatności

1. Postanowienia ogólne
1.1. Niniejsza Polityka Prywatności określa zasady przetwarzania i ochrony danych osobowych użytkowników serwisu internetowego https://bcrstudio.pl/shop 1.2. Administratorem danych osobowych jest  Bolesławieckie Centrum Ruchu oraz Trener Amadeusz Dziemianko z siedzibą pod adresem: Staszica 9, 59-700 Bolesławiec, NIP:  6121879187  . 1.3. Kontakt w sprawach dotyczących ochrony danych osobowych: studiotreningowebcr@gmail.com
<br/>
2. Zakres i cel przetwarzania danych
2.1. Dane osobowe użytkowników są zbierane i przetwarzane w celu realizacji zamówień, obsługi klienta oraz spełnienia obowiązków prawnych administratora. 2.2. Dane mogą być wykorzystywane do wysyłki informacji marketingowych tylko za zgodą użytkownika. 2.3. Administrator przetwarza następujące dane osobowe:

Imię i nazwisko

Adres e-mail

Numer telefonu

Adres zamieszkania (jeśli wymagane do realizacji zamówienia)

Dane dotyczące płatności

3. Udostępnianie danych osobowych
3.1. Dane osobowe mogą być udostępniane podmiotom współpracującym przy realizacji zamówień (np. operatorom płatności, podmiotom obsługującym dostawy) w zakresie niezbędnym do wykonania usługi. 3.2. Administrator nie sprzedaje danych osobowych użytkowników osobom trzecim. 3.3. Dane mogą być przekazywane organom państwowym, jeśli wymagają tego obowiązujące przepisy prawa.
<br/>
4. Prawa użytkowników
4.1. Użytkownicy mają prawo do:

Dostępu do swoich danych osobowych

Sprostowania danych

Usunięcia danych („prawo do bycia zapomnianym”)

Ograniczenia przetwarzania

Przenoszenia danych

Sprzeciwu wobec przetwarzania 4.2. Wnioski dotyczące realizacji praw można zgłaszać na adres e-mail: studiotreningowebcr@gmail.com. 4.3. Użytkownicy mają prawo wniesienia skargi do organu nadzorczego (UODO) w przypadku naruszenia przepisów o ochronie danych osobowych.
<br/>
5. Okres przechowywania danych
5.1. Dane osobowe będą przechowywane przez okres niezbędny do realizacji zamówienia oraz przez czas wynikający z obowiązków prawnych (np. rachunkowych, podatkowych). 5.2. W przypadku zgody na przetwarzanie danych w celach marketingowych, dane będą przechowywane do momentu cofnięcia zgody przez użytkownika.
<br></br>
6. Zabezpieczenie danych
6.1. Administrator stosuje odpowiednie środki techniczne i organizacyjne zapewniające ochronę danych osobowych przed nieuprawnionym dostępem, utratą lub zniszczeniem. 6.2. Dostęp do danych mają jedynie osoby upoważnione i zobowiązane do zachowania poufności.
<br></br>
7. Postanowienia końcowe
7.1. Administrator zastrzega sobie prawo do wprowadzenia zmian w Polityce Prywatności, o czym użytkownicy zostaną poinformowani poprzez stronę internetową. 7.2. Wszelkie pytania dotyczące przetwarzania danych osobowych można kierować na adres e-mail: studiotreningowebcr@gmail.com</p>
		</div>
	);
};

export default PolitykaPrywatnosci;
