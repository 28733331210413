import React, { useState } from "react";
import "../components/Shop.scss";
import ValentinoShirt from "./ValentinoShirt";
import NormalShirt from "./NormalShirt";

const Shop = () => {
	const [selectedShirt, setSelectedShirt] = useState("valentino");

	const handleClick = (shirtType) => {
		setSelectedShirt(shirtType);

		// Znajdujemy przycisk, do którego dodajemy animację
		const button = document.querySelector(`button.${shirtType}`);
		if (button) {
			const icon = document.createElement("span");
			icon.classList.add(shirtType === "valentino" ? "heart" : "dumbbell");
			icon.innerHTML = shirtType === "valentino" ? "❤️" : "🏋️‍♂️";
			icon.style.left = `${Math.random() * 80}%`;
			icon.style.top = `${Math.random() * 80}%`;
			button.appendChild(icon);

			// Usuwamy ikonę po animacji
			setTimeout(() => icon.remove(), 600);
		}
	};

	return (
		<div className="tshirt-customizer-wrapper">
			<h1 className="component-header">Dostępne w studio</h1>

			{/* Przełącznik koszulek */}
			<div className="shirt-switcher">
				<button
					className={`valentino ${selectedShirt === "valentino" ? "active" : ""}`}
					onClick={() => handleClick("valentino")}
				>
					Walentynkowo 
				</button>
				<button
					className={`normal ${selectedShirt === "normal" ? "active" : ""}`}
					onClick={() => handleClick("normal")}
				>
					 Standardowa 
				</button>
			</div>

			{/* Renderowanie odpowiedniego komponentu */}
			{selectedShirt === "valentino" ? <ValentinoShirt /> : <NormalShirt />}
		</div>
	);
};

export default Shop;
