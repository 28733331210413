import React, { useState } from "react";
import maleT from "../img/koszulki/valentino.png";
import femaleT from "../img/koszulki/famaleValentino.png";

const sizeRows = [
	{ iconClass: "fa-solid fa-person-dress", sizes: ["S", "M", "L", "XL"] },
	{ iconClass: "fa-solid fa-person", sizes: ["S", "M", "L", "XL", "XXL", "XXXL"] },
];

const ValentinoShirt = () => {
	const [isMale, setIsMale] = useState(true);

	const tshirtImage = isMale ? maleT : femaleT;
	const tshirtText = isMale
		? "Męska: Zjedz to Żeliwo"
		: "Damska: Spoć się dla mnie mięśniaku";

	return (
		<>
			<div className="tshirt-preview-container">
				<img src={tshirtImage} alt="Koszulka" className="tshirt-image" />
				<div className="price-info">Cena: 100 zł</div>
			</div>

			{/* Sekcja wyboru płci */}
			<div className="tshirt-options-container">
				<h3>Wybierz typ koszulki:</h3>
				<div className="size-row">
					<i
						className={`fa-solid fa-person-dress ${!isMale ? "active" : ""}`}
						onClick={() => setIsMale(false)}></i>
					<i
						className={`fa-solid fa-person ${isMale ? "active" : ""}`}
						onClick={() => setIsMale(true)}></i>
				</div>

				{/* Pojedyncza czerwona opcja koloru */}
				<div className="color-options">
					<h3>Kolor koszulki:</h3>
					<div className="color-circle-container">
						<div className="color-circle red" />
					</div>
				</div>

				{/* Informacja o rozmiarach */}
				<div className="size-info">
					<h4>Dostępne rozmiary:</h4>
					{sizeRows.map((row, index) => (
						<div key={index} className="size-row">
							<i className={row.iconClass}></i>
							<span>{row.sizes.join(", ")}</span>
						</div>
					))}
				</div>
        <div className="limited-stock">
					LICZBA SZTUK OGRANICZONA!!
				</div>				{/* Wyświetlanie dostępnego napisu */}
				<div className="text-info">
					<h3>Napis na koszulce:</h3>
					<div className="text-option">{tshirtText}</div>
				</div>
				

			</div>
		</>
	);
};

export default ValentinoShirt;
